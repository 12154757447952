import React, { useEffect, useState, useRef } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import XLSX from 'xlsx';

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { PGET, GET } from 'helpers/api'
import { formatMoney, formatDate, returnSign, findFromArrayById } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const filterDataRef = useRef({ posId: '' })

	const [poses, setPoses] = useState([])
	const [organizations, setOrganizations] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [totalAmount, setTotalAmont] = useState(0)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		search: null,
	})
	const [pagination, setPagination] = useState({
		url: '/services/web/api/report-frozen-products',
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = "/services/web/api/report-frozen-products"
		var urlParams = ""
		checkFilter()

		if (filterDataRef?.current?.posId) {
			urlParams += returnSign(urlParams) + 'posId=' + filterDataRef.current.posId
			filterDataRef.current = null
		}
		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.organizationId)
			urlParams += returnSign(urlParams) + 'organizationId=' + filterData.organizationId
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/web/api/report-frozen-products" + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = "/services/web/api/report-frozen-products-excel" + urlParams + returnSign(filterUrl)

			// var excelHeaders = [[
			// 	t('POS'), t('PRODUCT_ID'), t('NAME_OF_PRODUCT'), t('BARCODE'), t('BALANCE'),
			// 	t('REGULATORY_BALANCE'), t('EXCESS'), t('FROZEN_FUNDS'), t('CURRENCY')
			// ]]
			var children = document.getElementById('thead').childNodes
			var excelHeaders = [[]]
			for (let i = 0; i < children.length; i++) {
				excelHeaders[0].push(children[i]?.innerHTML?.toString())
			}
			const response = await GET(filterUrl)
			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i].rowNum + '. ' + response[i].organizationName)
				excelItems.push(response[i].productName)
				excelItems.push(response[i].productBarcode)
				excelItems.push(response[i].balance)
				excelItems.push(response[i].normativeBalance)
				excelItems.push(response[i].avgProductDay)
				excelItems.push(response[i].overBalance)
				excelItems.push(response[i].frozenAmount)
				excelItems.push(formatDate(response[i].createdDate))
				excelHeaders.push(excelItems)
			}
			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('FROZEN_FUNDS') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}

			filterUrl = "/services/web/api/report-frozen-products-total" + urlParams
			const temporaryTotalAmount = await GET(filterUrl)
			setTotalAmont(temporaryTotalAmount)
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET("services/web/api/report-frozen-products", {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': "services/web/api/report-frozen-products", 'response': response })
		}

		const temporaryTotalAmount = await GET("/services/web/api/report-frozen-products-total", {}, { loader: true })
		setTotalAmont(temporaryTotalAmount)
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
			setFilterData({ ...filterData, posId: response[0]['id'] })
			filterDataRef.current.posId = response[0]['id']
			searchWithFilter()
		}
	}

	async function getOrganizations() {
		const response = await GET('/services/web/api/organization-helper')
		if (response) {
			setOrganizations(response)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		getPoses()
		getOrganizations()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (filterData.search != null) {
			searchWithFilter(false)
		}
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('FROZEN_FUNDS')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('SUPPLIER')}</label>
												<Select
													options={organizations}
													value={organizations.find(option => option.id === filterData.organizationId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'organizationId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center">
								<h5 className="text-end">
									<b className="me-2">{t('TOTAL_SUM')}:</b>
									<span className="me-1">{formatMoney(totalAmount.totalAmount)}</span>
									<b>{totalAmount.currencyName}</b>
								</h5>
								<h5 className="text-end">
									<b className="me-2">{t('FROZEN_AMOUNT')}:</b>
									<span className="me-1">{formatMoney(totalAmount.frozenAmount)}</span>
									<b>{totalAmount.currencyName}</b>
								</h5>
								<h5 className="text-end">
									<b className="me-2">{t('PERCENTAGE')}:</b>
									<span className="me-1">{formatMoney(totalAmount.percent)}%</span>
									<b>{totalAmount.currencyName}</b>
								</h5>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.organizationId &&
									<span className="me-2">
										<b>{t('ORGANIZATION')}: </b> {findFromArrayById(organizations, filterData.organizationId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr id="thead">
									<th>{t('ORGANIZATION')}</th>
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									<th className="text-center">{t('ARTIKUL')}</th>
									<th className="text-center">{t('BALANCE')}</th>
									<th className="text-center">{t('REGULATORY_BALANCE')}</th>
									<th className="text-center">{t('AVERAGE_NUMBER_PER_DAY')}</th>
									<th className="text-center">{t('EXCESS')}</th>
									<th className="text-end">{t('FROZEN_FUNDS')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.organizationName}</td>
										<td>{item.productName}</td>
										<td className="text-center">{item.productBarcode}</td>
										<td className="text-center">{item.productArtikul}</td>
										<td className="text-center">{formatMoney(item.balance)}</td>
										<td className="text-center">{formatMoney(item.normativeBalance)}</td>
										<td className="text-center">{formatMoney(item.avgProductDay)}</td>
										<td className="text-center">{formatMoney(item.overBalance)}</td>
										<td className="text-end">{formatMoney(item.frozenAmount)} {item.currencyName}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>
		</>
	)
}

export default Index
