import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Select from 'react-select'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

import { GET, POST, PUT, FILE } from 'helpers/api'

function CreateUpdate() {
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();

	const ymaps = useRef(null);

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]
	const rounds = [{ "id": 100, "name": "100" }, { "id": 500, "name": "500" }]
	const defaultState = {
		center: [41.311081, 69.240562],
		zoom: 11,
		controls: []
	};

	const [regions] = useState([{ "id": 1, "name": "Андижанская область" }, { "id": 2, "name": "Бухарская область" }, { "id": 3, "name": "Джизакская область" }, { "id": 4, "name": "Кашкадарьинская область" }, { "id": 5, "name": "Навоийская область" }, { "id": 6, "name": "Наманганская область" }, { "id": 7, "name": "Самаркандская область" }, { "id": 8, "name": "Сурхандарьинская область" }, { "id": 9, "name": "Сырдарьинская область" }, { "id": 10, "name": "город Ташкент" }, { "id": 11, "name": "Ташкентская область" }, { "id": 12, "name": "Ферганская область" }, { "id": 13, "name": "Хорезмская область" }, { "id": 14, "name": "Респ. Каракалпакстан" }])
	const [cities, setCities] = useState([])
	const [data, setData] = useState({
		activated: true,
		phone: "",
		regionId: 10,
		cityId: null,
		gpsPointX: 41.311081,
		gpsPointY: 69.240562,
		imageUrl: "",
		posTypeList: [],
		cashierList: [],
		merchandiserList: [],
		saleMinus: false,
		defaultCurrency: 1,
		hidePriceIn: true,
		wholesalePriceIn: false,
		loyaltyApi: "",
		ofd: false,
		paymePay: {},
		clickPay: {},
		uzumPay: {},
		roundUp: false,
		roundAmount: 0,
		clientSms: false,
		organizationSms: false,
		settings: {},
	})

	async function createUpdate(e) {
		e.preventDefault()
		var dataCopy = { ...data }
		dataCopy.settings = JSON.stringify(dataCopy.settings)
		var response;
		if (dataCopy.id) {
			response = await PUT('/services/web/api/pos', dataCopy, { loader: true })
			if (response) {
				navigate('/pos')
			}
		} else {
			response = await POST('/services/admin/api/pos', dataCopy, { loader: true })
			if (response) {
				navigate('/admin/clients')
			}
		}
	}

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);

		const response = await FILE('/services/web/api/upload/image', formData);
		setData({ ...data, 'imageUrl': response.url })
	}

	async function selectRegion(option) {
		setData({ ...data, 'regionId': option.id, 'cityId': null })
		const response = await GET('/services/web/api/city-helper/' + option.id)
		if (response.length > 0) {
			setCities(response)
		}
	}

	async function getData() {
		var response = await GET('/services/web/api/pos/' + id)

		const temporaryCities = await GET('/services/web/api/city-helper/' + response.regionId)
		setCities(temporaryCities)

		if (!response.paymePay) {
			response.paymePay = {
				"merchant_id": "",
				"merchant_service_id": "",
				"merchant_service_user_id": "",
				"merchant_secret_key": ""
			}
		}
		if (!response.clickPay) {
			response.clickPay = {
				"merchant_id": "",
				"merchant_service_id": "",
				"merchant_service_user_id": "",
				"merchant_secret_key": ""
			}
		}
		if (!response.uzumPay) {
			response.uzumPay = {
				"merchant_id": "",
				"merchant_service_id": "",
				"merchant_service_user_id": "",
				"merchant_secret_key": ""
			}
		}
		if (!response.settings) {
			response.settings = {}
		} else {
			response.settings = JSON.parse(response.settings)
		}
		setData(response)
	}

	function handleDataChange(e, type) {
		var dataCopy = { ...data }
		if (type === "payme") {
			dataCopy.paymePay[e.target.name] = e.target.value
			setData(dataCopy)
		}
		if (type === "click") {
			dataCopy.clickPay[e.target.name] = e.target.value
			setData(dataCopy)
		}
		if (type === "uzum") {
			dataCopy.uzumPay[e.target.name] = e.target.value
			setData(dataCopy)
		}
	}

	function handleSettingsChange(e) {
		var dataCopy = { ...data }
		dataCopy.settings[e.target.name] = e.target.value
		if (e.target.name === 'uget') {
			dataCopy.settings['tirox'] = false
		}
		if (e.target.name === 'tirox') {
			dataCopy.settings['uget'] = false
		}
		setData(dataCopy)
	}

	async function getCities() {
		const response = await GET('/services/web/api/city-helper/' + data.regionId)
		if (response.length > 0) {
			setCities(response)
		}
	}

	useEffect(() => {
		if (id) {
			getData()
		} else {
			getCities()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('POS')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('EDIT')}</h6>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="row mb-5">
							<div className="col-md-2">
								<div className="con-upload">
									<div className="con-img-upload">
										{data.imageUrl &&
											<div className="img-upload">
												<button type="button" className="btn-x-file" onClick={() => setData({ ...data, 'imageUrl': '' })}>
													<i className="uil-times"></i>
												</button>
												<img src={process.env.REACT_APP_PROD_SERVER_URL + data.imageUrl}
													style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
											</div>
										}
										{!data.imageUrl &&
											<div className="con-input-upload">
												<input type="file" onChange={(e) => fileUpload(e)} />
												<span className="text-input fz14">
													{t('IMAGE2')}
												</span>
											</div>
										}
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>{t('SALE_POINT_NAME')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="name"
										value={data.name}
										onChange={(e) => setData({ ...data, 'name': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('TIN')}</label>
									<input type="number" className="form-control" name="tin"
										value={data.tin}
										onChange={(e) => setData({ ...data, 'tin': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('PHONE')}<span className="required-mark">*</span></label>
									<input type="number" className="form-control" name="phone"
										value={data.phone}
										onChange={(e) => setData({ ...data, 'phone': e.target.value })} />
								</div>
							</div>
							<div className="col-md-3">
								<YMaps
									query={{
										load: "package.full",
										apikey: 'b4de6db2-b765-41e3-9376-c9bd74b17a18'
									}}
								>
									<Map
										defaultOptions={{
											copyrightUaVisible: false,
											yandexMapDisablePoiInteractivity: true,
										}}
										style={{ height: '200px' }}
										defaultState={defaultState}
										onLoad={(e) => {
											ymaps.current = e;
										}}
										onClick={(event) => {
											const coords = event.get("coords");
											ymaps.current.geocode(coords).then(() => {
												setData({ ...data, 'gpsPointX': coords[0], 'gpsPointY': coords[1] })
											});
										}}>
										<Placemark geometry={[data.gpsPointX, data.gpsPointY]} />
									</Map>
								</YMaps>
								{/* <GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyDRnmjaTmZQmjt4sxKaNVW_DqOfp993Afc" }}
									defaultZoom={14}
									center={{ lat: data.gpsPointX, lng: data.gpsPointY }}
									onClick={selectLocation}>
									<Marker
										lat={data.gpsPointX}
										lng={data.gpsPointY}
									/>
								</GoogleMapReact> */}
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>{t('ADDRESS')}</label>
									<input type="text" className="form-control" name="address"
										value={data.address}
										onChange={(e) => setData({ ...data, 'address': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('REGION')}<span className="required-mark">*</span></label>
									<Select
										options={regions}
										value={regions.find(option => option.id === data.regionId) || ''}
										onChange={(option => selectRegion(option))}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group">
									<label>{t('CITY')}<span className="required-mark">*</span></label>
									<Select
										options={cities}
										value={cities.find(option => option.id === data.cityId) || ''}
										onChange={(e) => setData({ ...data, 'cityId': e.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<h5 className="fw-600">{t('SETTINGS')}</h5>
									<hr />
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('CURRENCY')}</p>
									<div className="col-md-4 p-0">
										<Select
											options={currencies}
											value={currencies.find(option => option.id === data.defaultCurrency)}
											onChange={(e) => setData({ ...data, 'defaultCurrency': e.id })}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('NEGATIVE_SALE')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="saleMinus"
											checked={data?.saleMinus}
											onChange={(e) => setData({ ...data, 'saleMinus': e.target.checked })} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('HIDE_ADMISSION_PRICE')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="hidePriceIn"
											checked={data?.hidePriceIn}
											onChange={(e) => setData({ ...data, 'hidePriceIn': e.target.checked })} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('HIDE_WHOLESALE_PRICE_BUTTON')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="wholesalePriceIn"
											checked={data?.wholesalePriceIn}
											onChange={(e) => setData({ ...data, 'wholesalePriceIn': e.target.checked })} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('HIDE_BANK_PRICE_BUTTON')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="bankPrice"
											checked={data?.bankPrice}
											onChange={(e) => setData({ ...data, 'bankPrice': e.target.checked })} />
									</div>
								</div>
								{/* <div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">Uget</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="uget"
											checked={data?.settings.uget}
											onChange={(e) => handleSettingsChange(e)} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">Tirox</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="tirox"
											checked={data?.settings.tirox}
											onChange={(e) => handleSettingsChange(e)} />
									</div>
								</div> */}
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('LOYALTY')}</p>
									<div className="col-md-4 p-0">
										<input type="text" className="form-control" name="loyaltyApi"
											value={data?.loyaltyApi}
											onChange={(e) => setData({ ...data, 'loyaltyApi': e.target.value })} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">Telegram ID</p>
									<div className="col-md-4 p-0">
										<input type="text" className="form-control" name="telegramGroupId"
											value={data?.telegramGroupId}
											onChange={(e) => setData({ ...data, 'telegramGroupId': e.target.value })} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('CLIENT_SMS')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="clientSms"
											checked={data?.clientSms}
											onChange={(e) => setData({ ...data, 'clientSms': e.target.checked })} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('ORGANIZATION_SMS')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="organizationSms"
											checked={data?.organizationSms}
											onChange={(e) => setData({ ...data, 'organizationSms': e.target.checked })} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2 d-none">
									<p className="fw-600 vertical-center">{t('OFD')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="ofd"
											checked={data?.ofd}
											onChange={(e) => setData({ ...data, 'ofd': e.target.checked })} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('ROUNDING')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="roundUp"
											checked={data?.roundUp}
											onChange={(e) => setData({ ...data, 'roundUp': e.target.checked, roundAmount: e.target.checked ? 100 : 0 })} />
									</div>
								</div>
								{data.roundUp &&
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('ROUNDING')}</p>
										<div className="col-md-4 p-0">
											<Select
												options={rounds}
												value={rounds.find(option => option.id === data.roundAmount)}
												onChange={(e) => setData({ ...data, 'roundAmount': e.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
								}
							</div>

							<div className="col-md-6">
								<div className="form-group">
									<h5 className="fw-600">{t('ADDITIONALLY')}</h5>
									<hr />
								</div>

								<div className="d-flex gap-3">
									<div className="w-100">
										<h5 className="mb-2">Payme</h5>
										<div className="form-group">
											<label>merchant_id</label>
											<input type="text" className="form-control" name="merchant_id"
												value={data?.paymePay?.merchant_id}
												onChange={(e) => handleDataChange(e, 'payme')} />
										</div>
										<div className="form-group">
											<label>merchant_service_id</label>
											<input type="text" className="form-control" name="merchant_service_id"
												value={data?.paymePay?.merchant_service_id}
												onChange={(e) => handleDataChange(e, 'payme')} />
										</div>
										<div className="form-group">
											<label>merchant_service_user_id</label>
											<input type="text" className="form-control" name="merchant_service_user_id"
												value={data?.paymePay?.merchant_service_user_id}
												onChange={(e) => handleDataChange(e, 'payme')} />
										</div>
										<div className="form-group">
											<label>merchant_secret_key</label>
											<input type="text" className="form-control" name="merchant_secret_key"
												value={data?.paymePay?.merchant_secret_key}
												onChange={(e) => handleDataChange(e, 'payme')} />
										</div>
									</div>

									<div className="w-100">
										<h5 className="mb-2">Click</h5>
										<div className="form-group">
											<label>merchant_id</label>
											<input type="text" className="form-control" name="merchant_id"
												value={data?.clickPay?.merchant_id}
												onChange={(e) => handleDataChange(e, 'click')} />
										</div>
										<div className="form-group">
											<label>merchant_service_id</label>
											<input type="text" className="form-control" name="merchant_service_id"
												value={data?.clickPay?.merchant_service_id}
												onChange={(e) => handleDataChange(e, 'click')} />
										</div>
										<div className="form-group">
											<label>merchant_service_user_id</label>
											<input type="text" className="form-control" name="merchant_service_user_id"
												value={data?.clickPay?.merchant_service_user_id}
												onChange={(e) => handleDataChange(e, 'click')} />
										</div>
										<div className="form-group">
											<label>merchant_secret_key</label>
											<input type="text" className="form-control" name="merchant_secret_key"
												value={data?.clickPay?.merchant_secret_key}
												onChange={(e) => handleDataChange(e, 'click')} />
										</div>
									</div>

									<div className="w-100">
										<h5 className="mb-2">Uzum</h5>
										<div className="form-group">
											<label>merchant_id</label>
											<input type="text" className="form-control" name="merchant_id"
												value={data?.uzumPay?.merchant_id}
												onChange={(e) => handleDataChange(e, 'uzum')} />
										</div>
										<div className="form-group">
											<label>merchant_service_id</label>
											<input type="text" className="form-control" name="merchant_service_id"
												value={data?.uzumPay?.merchant_service_id}
												onChange={(e) => handleDataChange(e, 'uzum')} />
										</div>
										<div className="form-group">
											<label>merchant_service_user_id</label>
											<input type="text" className="form-control" name="merchant_service_user_id"
												value={data?.uzumPay?.merchant_service_user_id}
												onChange={(e) => handleDataChange(e, 'uzum')} />
										</div>
										<div className="form-group">
											<label>merchant_secret_key</label>
											<input type="text" className="form-control" name="merchant_secret_key"
												value={data?.uzumPay?.merchant_secret_key}
												onChange={(e) => handleDataChange(e, 'uzum')} />
										</div>
									</div>
								</div>

							</div>
						</div>

						<div className="btn-wrapper">
							{data.id ?
								<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/pos">{t('CANCEL')}</Link>
								:
								<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/admin/clients">{t('CANCEL')}</Link>
							}
							<button type="submit" className="btn btn-primary btn-rounded btn-wide btn-wide">{t('SAVE')}</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate

