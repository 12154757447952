import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET } from 'helpers/api'
import { formatDate, formatMoney, checkRole } from 'helpers/helpers'

function Index() {
	const URL = '/services/web/api/pos-pageList'
	const { t } = useTranslation()


	const settings = useSelector(state => state.settings)

	const canUpdate = checkRole('POS_SETTINGS_POS_EDIT')

	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET('/services/web/api/pos-pageList', {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET('/services/web/api/pos-pageList', { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('POSES')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('ID')}</th>
									<th>{t('BILLING_ID')}</th>
									<th>{t('IMAGE2')}</th>
									<th>{t('SALE_POINT_NAME')}</th>
									<th className="text-center">{t('DATE_OF_CREATION')}</th>
									<th className="text-center text-nowrap">{t('BALANCE')}</th>
									<th className="text-center">{t('ACTIVATED')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.id}</td>
										<td><b>I-{item.id}</b></td>
										<td>
											{item.imageUrl ?
												<img src={process.env.REACT_APP_PROD_SERVER_URL + item.imageUrl} alt="react" width="50" height="50" />
												:
												<div className="table-no-image">
													<i className="uil uil-camera position-relative fz-20" style={{ 'top': '20%' }}></i>
												</div>
											}
										</td>
										<td>{item.name}</td>
										<td className="text-center">{formatDate(item.createdDate)}</td>
										<td className="text-center">
											{item.balance >= 0 ?
												<span className="bg-primary bg-border text-white text-nowrap">{formatMoney(item.balance)}</span>
												:
												<span className="bg-danger bg-border text-white text-nowrap">{formatMoney(item.balance)}</span>
											}
										</td>
										<td className="text-center">
											{item.activated ?
												<span className="bg-primary bg-border text-white">{t('YES')}</span>
												:
												<span className="bg-danger bg-border text-white">{t('NO')}</span>
											}
										</td>
										<td>
											<div className="d-flex justify-content-center">
												{canUpdate &&
													<Link className="table-action-button table-action-primary-button"
														to={'/pos-update/' + item.id}>
														<i className="uil-edit-alt"></i>
													</Link>
												}
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
